@import url('https://fonts.googleapis.com/css2?family=Familjen+Grotesk:wght@400;700&display=swap');
@import "~@fontsource/space-grotesk";
@import "~@fontsource/darker-grotesque";

:root {
  /* darkmode color */
  --colorbackground: #1c1c1c;
  --colorprimary: #f5f5dc;
  --colorsecondary: #d7d7a8;
  --coloraccent: #507963;

  /* lightmode color */
  /* tbd */
  
  /* fonts */
  --ff: Familjen Grotesk;
  --ff-links: Space Grotesk;
  --ff-details: Darker Grotesque;

  /* font size */
  --font-xxs: 2rem;
  --font-xs: 2.5rem;
  --font-s: 3rem;
  --font-m: 3.5rem;
  --font-l: 4rem;
  --font-xl: 4.5rem;

  /* margins */
  --margin-xxs: 3rem;
  --margin-xs: 4rem;
  --margin-s: 5rem;
  --margin-m: 6rem;
  --margin-l: 7rem;
  --margin-xl: 8rem;

  /* text */
  /* font weigh - font size - line height - font family */
  --homelink-contact: normal 48px/normal var(--ff); /* hi! */
  --h1-contact: bold 56px/normal var(--ff); /* hi! */
  --h2-catch: normal 32px/normal var(--ff-details); /* work about contact */
  --h3: normal 28px/normal var(--ff-details); /* devops bachelor msc */
  --contact-a: normal 20px/normal var(--ff-links); /* li tw ig gi */
  --h5: normal 20px/normal var(--ff); /* valence spain */
}

.work {
  margin: 0;
  padding-bottom: 40px;
}

.about {
  margin: 0;
  padding-bottom: 40px;
}

.contact {
  margin: 0;
  padding-bottom: 40px;
}


/* react Link */
.contact-homelink {
  font: var(--homelink-contact)
}

/* div */
.contact-parent-container {
  display: flex;
  height: 100vh;
  flex-wrap: wrap;
}
.contact-first-column {
  display: flex;
  align-items: start;
  flex: 1;
  padding: 96px 94px;
}
.contact-second-column {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  flex: 5;
  padding: 96px 94px;
}
.contact-hi-div {
  flex: 2;
}
.contact-h2-div {
  flex: 4;
  flex-direction: column;
}
.contact-empty-horizontal {
  flex: 1;
}
.contact-info {
  display: flex;
  flex-direction: column;
  align-items: start;
  flex: 2;
}
.contact-media {
  display: flex;
  flex-direction: row;
  gap: 48px;
}
.contact-empty-space {
  display: flex;
  flex: 4;
}

/* h1 & h2 */
.contact-h1-hook {
  font: var(--h1-contact);
  margin: 0;
}
.contact-h1-question {
  font: var(--h1-contact);
  margin: 0;
}
.contact-h2 {
  font: var(--h2-catch);
  color: var(--colorprimary);
  margin: 0;
  margin-bottom: 24px;
}

/* a */
.contact-a {
  font: var(--contact-a);
  color: var(--colorprimary);
  margin: 0;
}
.contact-mail-a {
  font: var(--contact-a);
  color: var(--colorprimary);
  margin-bottom: 16px;
}

@media (max-width: 500px) {
  .work {
    margin: 0;
    padding-bottom: 40px;
  }
  .about {
    margin: 0;
    padding-bottom: 40px;
  }
  .contact {
    margin: 0;
    padding-bottom: 40px;
  }

  .contact-homelink {
    font: normal 32px/normal var(--ff);
  }
  
  /* div */
  .contact-parent-container {
    display: flex;
    height: 100vh;
    flex-wrap: wrap;
  }
  .contact-first-column {
    display: flex;
    padding: 2rem 1rem;
    height: 5vh;
  }
  .contact-second-column {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 7rem 1rem;
    justify-content: flex-start;
    margin: 0;
  }
  .contact-h2-div {
    flex-direction: column;
    row-gap: 8px;
  }
  .contact-empty-horizontal {
    flex: 0;
  }
  .contact-info {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .contact-media {
    display: flex;
    flex-direction: row;
    gap: 16px;
    flex-wrap: wrap;
  }
  .contact-empty-space {
    display: flex;
  }
  
  /* h1 & h2 */
  .contact-h1-hook {
    font: bold 44px/normal var(--ff);
    margin: 0;
  }
  .contact-h1-question {
    font: bold 44px/normal var(--ff);
    margin-top: 5rem;
    margin-bottom: 2rem;
  }
  .contact-h2 {
    font: var(--h2-catch);
    color: var(--colorprimary);
    margin: 0;
    margin-bottom: 16px;
  }
  
  /* a */
  .contact-a {
    font: normal 10px/normal var(--ff-links);
    color: var(--colorprimary);
    margin: 0;
  }
  .contact-mail-a {
    font: normal 28px/normal var(--ff-details);
    color: var(--colorprimary);
    margin-bottom: 32px;
  }
}